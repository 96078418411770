import TICKET_API from "../api/ticket";
import USER_API from "../api/user";
import axios from "../plugins/axios";
import { getColor, IMAGE_TYPE } from "../utils";

export default async function loadDocuments(ticketId) {
  return TICKET_API.getDocuments(ticketId).then(({ data }) => {
    let ITILFollowup = [];
    data.forEach(async document => {
      //Get the document meta data
      let documentData = USER_API.getDocument(document.documents_id);
      //Get the document file in blob
      let documentImage = USER_API.getDocument(document.documents_id, true);
      // CCA modif l'affichage du nom des rédacteurs de photos
      const followUp = axios.all([documentData, documentImage]).then(
        axios.spread(async (docData, docImg) => {
          let iTFollowUp = docData.data;
          iTFollowUp.color = getColor();
          iTFollowUp.actionType = IMAGE_TYPE;
          iTFollowUp.img = URL.createObjectURL(docImg.data);
          iTFollowUp.doc = docImg.data;
          return iTFollowUp;
        })
      );
      ITILFollowup.push(followUp);
    });

    return ITILFollowup;
  });
}
