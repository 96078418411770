import { reactive, toRefs } from "@vue/composition-api";
//import USER_API from "../api/user";
import { loadStates, TASK_TYPE } from "../utils";

export default function getTasks(ticketId, tasks) {
  const state = reactive({
    TicketTaskLoaded: loadStates.INITIAL_STATE,
    TicketTask: []
  });
  // CCA modif l'affichage du nom des rédacteurs de taches
  const loadTicketTasks = () => {
    state.TicketTaskLoaded = loadStates.PENDING;
    state.TicketTask = tasks.value.map(el => {
      el.actionType = TASK_TYPE;
      el.color = "warning";
      if (!el.user_name) el.user_name = "...";
      return el;
    });

    /*state.TicketTask.forEach(async (el, index) => {
      await USER_API.getUserData(el.users_id).then(({ data }) => {
        state.TicketTask[index].user_name = data.firstname.concat(
          " ",
          data.realname
        );
      });
    });*/
    state.TicketTaskLoaded = loadStates.SUCCESS;
  };

  return {
    ...toRefs(state),
    loadTicketTasks
  };
}
