import { ref } from "@vue/composition-api";
import USER_API from "../api/user";
export default function getTicket(ticketId) {
  const ticket = ref({});
  const loadTicket = () => {
    USER_API.getTicket(ticketId).then(({ data }) => {
      ticket.value = data;
    });
  };

  return {
    ticket,
    loadTicket
  };
}
