import { render, staticRenderFns } from "./TaskItem.vue?vue&type=template&id=144e6d79&scoped=true&"
import script from "./TaskItem.vue?vue&type=script&lang=js&"
export * from "./TaskItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "144e6d79",
  null
  
)

export default component.exports