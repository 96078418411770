<template>
  <v-dialog
    v-model="commentDialog"
    persistent
    max-width="600px"
    v-if="AddCommentAction"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="blue darken-1"
        :fab="$vuetify.breakpoint.smAndDown"
        dark
        :small="$vuetify.breakpoint.smAndDown"
        :large="$vuetify.breakpoint.mdAndUp"
        :class="{ actionBtn: $vuetify.breakpoint.mdAndUp }"
        v-on="on"
      >
        <v-icon v-if="isMobile" dark>mdi-comment-text-multiple-outline</v-icon>
        <template v-else>
          <v-icon dark>mdi-comment-text-multiple-outline</v-icon>
          Commentaire</template
        >
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span :class="{ headline: $vuetify.breakpoint.mdAndUp }"
          >Traitement de l’intervention</span
        >
      </v-card-title>
      <v-card-subtitle
        ><span class="subtitle-1">Ajout d’un complément d’information</span>
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" v-if="success">
              <v-alert type="success">
                Opération effectuée avec succès
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="error">
              <v-alert type="error">
                {{ error.message }}
              </v-alert>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <v-textarea
                solo
                name="input-7-4"
                label="Commentaire"
                v-model="commentText"
                :loading="commentLoading"
              ></v-textarea>
              <div class="d-flex mt-5">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="!commentText"
                  @click="sendComment"
                  >Envoyer commentaire</v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <v-file-input
                accept="image/*"
                label="Ajouter une photo"
                v-model="photo"
              ></v-file-input>
              <div class="d-flex justify-center" v-if="finalImg">
                <v-card class="mx-auto" width="150">
                  <v-img :src="finalImg.src" height="150px"></v-img>
                </v-card>
                <div class="zoneInfo">
                  <div class="titre">{{ photo.name }}</div>
                  <div class="detailInfo">
                    Type : {{ photo.type }} <br />Taille originale :
                    {{ W_org }} X {{ H_org }} px <br />Taille finale :
                    {{ W_fin }} X {{ H_fin }} px
                  </div>
                </div>
              </div>
              <div class="d-flex mt-5">
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="!finalImg" @click="sendImage"
                  >Envoyer photo</v-btn
                >
              </div>
            </v-col>
            <v-overlay absolute :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeCommentDialog">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { getColor, IMAGE_TYPE } from "../../utils";
import TICKET_API from "../../api/ticket";
import USER_API from "../../api/user";
import { blobToImage } from "../../utils/upload";
import axios from "../../plugins/axios";
import { mapGetters } from "vuex";
import loadImage from "blueimp-load-image/js/load-image";
require("blueimp-load-image/js/load-image.all.min.js");

const Res_target = 4000000;

export default {
  props: {
    AddCommentAction: {
      type: Boolean,
      default: false
    },
    ticketId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      commentDialog: false,
      commentText: undefined,
      photo: undefined,
      finalImg: undefined,
      exifImg: undefined,
      hasExif: false,
      W_org: undefined,
      H_org: undefined,
      Res_target,
      Res_org: undefined,
      W_fin: undefined,
      H_fin: undefined,
      Ratio_comp: undefined,
      commentLoading: false,
      photoLoading: false,
      commentError: undefined,
      overlay: false,
      success: false,
      error: false
    };
  },
  computed: {
    ...mapGetters(["getActiveEntity"]),
    finaleImgSrc() {
      return this.finalImg ? this.finalImg.src : null;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    closeCommentDialog() {
      this.commentDialog = false;
      this.commentText = undefined;
      this.photo = undefined;
      this.finalImg = undefined;
      this.commentError = undefined;
      this.exifImg = undefined;
    },
    sendComment() {
      this.commentLoading = true;
      this.overlay = true;
      TICKET_API.sendComment(
        this.ticketId,
        this.commentText,
        this.getActiveEntity.id
      )
        .then(({ data }) => {
          this.getITILFollowupByID(data.id);
          this.setSuccessState();
        })
        .catch(error => {
          this.commentError = error.response.data;
          this.setError(error.response.data[1]);
        })
        .finally(() => {
          this.commentLoading = false;
          this.overlay = false;
        });
    },
    async sendImage() {
      this.photoLoading = true;
      this.overlay = true;
      const image = this.exifImg;
      TICKET_API.addImageDoc(image, this.photo)
        .then(({ data }) => {
          TICKET_API.attachDocumentToItem(this.ticketId, data.id)
            .then(async () => {
              this.getDocumentById(data.id);
            })
            .catch(({ response }) => {
              this.commentError = response.data;
              this.setError(response.data[1]);
              this.photoLoading = false;
              this.overlay = false;
            });
        })
        .catch(({ response }) => {
          axios.defaults.headers["Content-Type"] = "application/json";
          this.commentError = response.data;
          this.setError(response.data[1]);
        });
    },
    async getDocumentById(documentID) {
      //Get the document meta data
      let documentData = USER_API.getDocument(documentID);
      //Get the document file in blob
      let documentImage = USER_API.getDocument(documentID, true);
      // CCA modif l'affichage du nom des rédacteurs de photos
      axios.all([documentData, documentImage]).then(
        axios.spread(async (docData, docImg) => {
          let iTFollowUp = docData.data;
          USER_API.getUserData(iTFollowUp.users_id).then(async ({ data }) => {
            iTFollowUp.user_name = data.firstname.concat(" ", data.realname);
            iTFollowUp.color = getColor();
            iTFollowUp.actionType = IMAGE_TYPE;
            iTFollowUp.img = URL.createObjectURL(docImg.data);
            iTFollowUp.doc = docImg.data;
            this.$emit("new-comment", iTFollowUp);
            this.setSuccessState();
            this.overlay = false;
            this.closeCommentDialog();
          });
        })
      );
    },
    /*async exifFileResize() {
      const canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        img = await blobToImage(this.photo);

      this.W_org = img.width;
      this.H_org = img.height;

      this.Res_org = this.W_org * this.H_org;
      this.Ratio_comp = Math.sqrt(Math.min(Res_target / this.Res_org, 1));
      this.W_fin = Math.round(this.W_org * this.Ratio_comp);
      this.H_fin = Math.round(this.H_org * this.Ratio_comp);
      canvas.width = this.W_fin;
      canvas.height = this.H_fin;

      ctx.drawImage(
        await blobToImage(this.photo),
        0,
        0,
        this.W_fin,
        this.H_fin
      );
      canvas.toBlob(async blob => {
        const exifCopy = await copyExif(this.photo, blob, this.photo.type);
        if (exifCopy) {
          this.hasExif = true;
          this.exifImg = new File([exifCopy], this.photo.name, {
            type: "image/jpeg"
          });
          this.finalImg = await blobToImage(exifCopy);
        } else {
          this.finalImg = await blobToImage(this.photo);
        }
      }, this.photo.type);
    },*/
    async uploadImage() {
      const canvas = document.createElement("canvas"),
        //ctx = canvas.getContext("2d"),
        img = await blobToImage(this.photo);

      this.W_org = img.width;
      this.H_org = img.height;

      this.Res_org = this.W_org * this.H_org;
      this.Ratio_comp = Math.sqrt(Math.min(Res_target / this.Res_org, 1));
      this.W_fin = Math.round(this.W_org * this.Ratio_comp);
      this.H_fin = Math.round(this.H_org * this.Ratio_comp);
      canvas.width = this.W_fin;
      canvas.height = this.H_fin;
      if (this.photo) {
        const data = await loadImage(
          this.photo,
          {
            maxWidth: this.W_fin,
            maxHeight: this.H_fin,
            meta: true,
            orientation: true,
            canvas: true
          } // Options
        );
        /*.then(function(data) {
            if (!data.imageHead)
              throw new Error("Could not parse image metadata");
            self.finalImg = data.image;
            loadImage.writeExifData(data.imageHead, data, "Orientation", 1);

            return new Promise(function(resolve) {
              data.image.toBlob(function(blob) {
                data.blob = blob;
                resolve(data);
              }, "image/jpeg");
            });
          })
          .then(function(data) {
            return loadImage.replaceHead(data.blob, data.imageHead);
          })
          .then(function(blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", self.photo.name); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(function(err) {
            console.error(err);
          });*/

        data.exif &&
          loadImage.writeExifData(data.imageHead, data, "Orientation", 1);
        //this.finalImg = data.image;
        this.photo.src = data.image.src;

        let newBlob = await new Promise(function(resolve) {
          data.image.toBlob(function(blob) {
            data.blob = blob;

            resolve(data);
          }, "image/jpeg");
        });

        data.blob = data.exif
          ? await loadImage.replaceHead(data.blob, data.imageHead)
          : data.blob;

        newBlob = data.blob;
        this.exifImg = new File([newBlob], this.photo.name, {
          type: this.photo.type
        });

        this.finalImg = await blobToImage(newBlob);

        /*ctx.drawImage(data.image, 0, 0, this.W_fin, this.H_fin);
        canvas.toBlob(async blob => {
          this.exifImg = new File([blob], this.photo.name, {
            type: "image/jpeg"
          });
        }, this.photo.type);*/
      }
    },
    setError(errorMsg) {
      this.error = {
        message: errorMsg
      };
      setTimeout(() => {
        this.error = undefined;
      }, 5000);
    },
    setSuccessState() {
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 5000);
    },
    getITILFollowupByID(ITILFollowupID) {
      TICKET_API.getITILFollowupByID(ITILFollowupID)
        .then(({ data }) => {
          let comment = data;
          comment.user_name = "";

          USER_API.getUserData(comment.users_id).then(({ data }) => {
            comment.user_name = data.firstname.concat(" ", data.realname);
            comment.color = getColor(comment.status);

            this.$emit("new-comment", comment);
            this.overlay = false;
            this.closeCommentDialog();
          });
        })
        .catch(() => {
          this.overlay = false;
        });
    }
  },
  watch: {
    commentDialog() {
      if (!this.commentDialog) {
        this.commentError = undefined;
      }
    },
    photo() {
      if (this.photo) {
        this.uploadImage();
      }
    }
  }
};
</script>
