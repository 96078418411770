import getITFollowUpSolutions from "./fetchITFollowUpSolutions";
import getFollowUps from "./fetchITFollowUp";
import getTasks from "./fetchTasks";
import getTicket from "./fetchTicket";

import { computed } from "@vue/composition-api";
import { loadStates } from "../utils";
import moment from "../plugins/moment";
import { EventBus } from "../event-bus";

export function setupTicketsComponent(ticketId, tasks, followUps, itSolutions) {
  const {
    ITILSolution,
    ITILSolutionLoaded,
    loadITFollowUpSolution
  } = getITFollowUpSolutions(ticketId, itSolutions);
  const { ITILFollowup, ITILFollowupLoaded, loadITFollowUps } = getFollowUps(
    ticketId,
    followUps
  );
  const { TicketTask, TicketTaskLoaded, loadTicketTasks } = getTasks(
    ticketId,
    tasks
  );
  const { ticket, loadTicket } = getTicket(ticketId);

  const list = computed(() => {
    const alldata = ITILFollowup.value
      .concat(ITILSolution.value)
      .concat(TicketTask.value);

    let sortedData = alldata.sort((a, b) => {
      if (moment(a.date_creation).isAfter(moment(b.date_creation))) {
        return 1;
      } else if (moment(b.date_creation).isAfter(moment(a.date_creation))) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedData;
  });

  const loaded = computed(
    () =>
      ITILFollowupLoaded.value === loadStates.SUCCESS &&
      ITILSolutionLoaded.value === loadStates.SUCCESS &&
      TicketTaskLoaded.value === loadStates.SUCCESS
  );

  const loading = computed(
    () =>
      ITILSolutionLoaded.value === loadStates.PENDING ||
      ITILFollowupLoaded.value === loadStates.PENDING ||
      TicketTaskLoaded.value === loadStates.PENDING
  );

  const loadData = () => {
    loadITFollowUpSolution();
    loadITFollowUps();
    loadTicketTasks();
    loadTicket();
  };

  function addNewITILFollowup(iTILFollowup) {
    ITILFollowup.value.push(iTILFollowup);
    EventBus.$emit("new-content", iTILFollowup.content, ticketId);
  }

  function addNewITILSolution(iTILSolution) {
    ITILSolution.value.push(iTILSolution);
    EventBus.$emit("new-content", iTILSolution.content, ticketId);
  }

  function addNewTask(task) {
    TicketTask.value.push(task);
    EventBus.$emit("new-content", task.content, ticketId);
  }

  return {
    ITILFollowup,
    ITILFollowupLoaded,
    ITILSolution,
    ITILSolutionLoaded,
    TicketTask,
    TicketTaskLoaded,
    loadData,
    ticket,
    loaded,
    loading,
    list,
    addNewITILFollowup,
    addNewITILSolution,
    addNewTask
  };
}
