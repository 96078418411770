<template>
  <v-card v-if="checkDoc" light flat width="100%">
    <div class="d-flex flex-wrap justify-md-end justify-center flex-md-nowrap">
      <div class="d-block text-truncate">
        <v-card-title class="pt-0 pt-md-4 subtitle-2 flex-shrink-0 flex-grow-0">
          <span
            class="flex-shrink-0 flex-grow-0"
            @click="downloadDocument(item.filename, item.doc)"
            title="Télécharger le document"
          >
            {{
              item.filename.slice(-4) === ".jpg" ||
              item.filename.slice(-4) === ".JPG" ||
              item.filename.slice(-4) === "jpeg" ||
              item.filename.slice(-4) === "JPEG"
                ? ""
                : item.filename
            }}</span
          >
        </v-card-title>

        <v-card-subtitle
          class="black--text font-weight-regular body-2 px-1 px-md-4 pb-0 pb-md-4 subtitle-2"
        >
          <span class="flex-shrink-0 flex-grow-0">
            {{
              item.name.includes("{") === true ? "document joint" : item.name
            }}</span
          >
        </v-card-subtitle>
      </div>
      <v-avatar
        class="ma-3"
        :size="$vuetify.breakpoint.smAndDown ? 80 : 125"
        tile
        @click="openDocument(item.filename, item.doc)"
        title="Ouvrir la photo dans une nouvelle fenêtre"
      >
        <v-img :src="item.img" aspect-ratio="1">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-avatar>
    </div>
  </v-card>
  <v-card v-else light flat width="100%">
    <div class="d-flex flex-wrap justify-md-end justify-center flex-md-nowrap">
      <div class="d-block text-truncate">
        <v-card-title class="pt-0 pt-md-4 subtitle-2 flex-shrink-0 flex-grow-0">
          <span class="flex-shrink-0 flex-grow-0">
            Ce document ne vous est pas accessible</span
          >
        </v-card-title>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { permissions } from "../../utils";

export default {
  name: "ImageItem",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters(["getUserProfiles"]),

    checkDoc() {
      const comment = "" + this.item.comment;
      const per = comment.substring(0, 3);
      return this.checkDocPermission(per);
    }
  },
  methods: {
    openDocument(filename, doc) {
      const url = URL.createObjectURL(doc);
      window.open(
        url,
        filename,
        "menubar=no, status=no, scrollbars=no, width=800, height=800"
      );
    },
    downloadDocument(filename, doc) {
      const url = window.URL.createObjectURL(new Blob([doc]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    checkDocPermission(permission) {
      const permissionSize = permission.length;
      if (permissionSize > 2) {
        const publique = permission[0];
        const occupant = permission[1];
        const investisseur = permission[2];

        let per = false;
        if (publique.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "publique",
            this.getUserProfiles
          );
          if (per) return true;
        }

        if (occupant.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "occupant",
            this.getUserProfiles
          );
          if (per) return true;
        }
        if (investisseur.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "investisseur",
            this.getUserProfiles
          );

          if (per) return true;
        }

        return false;
      }
      return false;
    }
  }
};
</script>

<style scoped></style>
