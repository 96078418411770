<template>
  <v-row>
    <v-col cols="12" class="py-0 py-md-3  ">
      <div class="incident" v-html="decodeContent(item.content)"></div>
    </v-col>
    <v-col cols="12" class="pa-0">
      <v-chip-group active-class="primary--text" class="d-flex justify-end">
        <v-chip x-small light label outlined class="pa-0">
          <v-icon x-small color="primary">event</v-icon>
        </v-chip>
        <v-chip x-small label>
          {{ moment(item.begin).format("L") }}
          {{ moment(item.begin).format("LTS") }}
        </v-chip>
        <v-chip x-small light label outlined class="pa-0">
          <v-icon x-small color="primary">arrow_forward</v-icon>
        </v-chip>
        <v-chip x-small label>
          {{ moment(item.end).format("L") }}
          {{ moment(item.end).format("LTS") }}
        </v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
</template>

<script>
import moment from "../../plugins/moment";
import { Decoder } from "../../mixins/decodeContent";

export default {
  name: "TaskItem",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mixins: [Decoder],
  methods: {
    moment
  }
};
</script>

<style scoped></style>
