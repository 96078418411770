<template>
  <v-dialog
    v-model="resolutionDialog"
    persistent
    max-width="600px"
    v-if="canAdd"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="blue darken-1"
        :fab="$vuetify.breakpoint.smAndDown"
        dark
        :small="$vuetify.breakpoint.smAndDown"
        :large="$vuetify.breakpoint.mdAndUp"
        :class="{ actionBtn: $vuetify.breakpoint.mdAndUp }"
        v-on="on"
      >
        <v-icon v-if="isMobile" dark>mdi-comment-check</v-icon>
        <template v-else
          ><v-icon dark>mdi-comment-check</v-icon>Avancement</template
        >
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span :class="{ headline: $vuetify.breakpoint.mdAndUp }"
          >Traitement et Résolution de l’intervention</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-col cols="12" v-if="error">
            <v-alert type="error">
              {{ error.message }}
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="success">
            <v-alert type="success">
              Opération effectuée avec succès
            </v-alert>
          </v-col>
          <v-container>
            <v-row>
              <v-col cols="4" class="pa-0">
                <span class="subtitle-2"> Attribuer à :</span>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-autocomplete
                  v-model="selectedUser"
                  :items="usersList"
                  :loading="emailLoading"
                  item-value="id"
                  :item-text="getUserName"
                  label="Qui ?"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                offset="1"
                class="d-flex justify-center pl-0 mt-n3"
              >
                <v-btn
                  color="blue lighten-2"
                  class="actionBtn"
                  :disabled="!selectedUser"
                  @click="addUser"
                  >ok</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="isPlanif">
            <v-row>
              <v-col cols="6" class="pa-0">
                <span class="subtitle-2"> Planifier une intervention :</span>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-autocomplete
                  v-model="selectedUser"
                  :items="usersList"
                  :loading="emailLoading"
                  item-value="id"
                  :item-text="getUserName"
                  label="Qui ?"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pa-0 mt-n3">
                <p class="text-justify">
                  <strong>Note: </strong>
                  Un email à la personne sera envoyé automatiquement
                </p>
              </v-col>
              <v-row class="d-flex .flex-wrap mt-n6 py-5">
                <v-col cols="12" md="6" class="pa-0">
                  <datetime-picker
                    label="Début"
                    v-model="task.start"
                    :date-error="dateError"
                    :error-msg="dateError ? dateErrorMsg : undefined"
                  >
                  </datetime-picker>
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    label="durée en minutes"
                    :rules="dureeRules"
                    v-model="task.minutes"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-textarea
                    solo
                    rows="2"
                    dense
                    name="input-7-4"
                    label="Commentaire"
                    :loading="taskLoading"
                    clearable
                    v-model="task.content"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="d-flex justify-center pa-0 mt-n3">
                  <v-btn
                    color="blue lighten-2"
                    class="actionBtn"
                    large
                    :disabled="!canAddTask || !selectedUser"
                    @click="addTask"
                    >Planifier</v-btn
                  >
                </v-col>
              </v-row>
            </v-row>
            <v-spacer></v-spacer>
            <v-divider class="mx-4" inset></v-divider>
            <v-spacer></v-spacer>
          </v-container>
          <v-row class="d-flex .flex-wrap mt-3">
            <v-col
              cols="12"
              md="6"
              class="pa-0 d-flex justify-center align-center"
            >
              <span class="subtitle-2 mt-n4"
                >Ajouter un commentaire à la résolution :</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <v-textarea
                solo
                rows="2"
                dense
                name="input-7-4"
                label="Commentaire"
                :loading="resolutionLoading"
                clearable
                v-model="resolutionText"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="pa-0 mt-n3">
              <v-btn
                color="blue lighten-2"
                class="actionBtn"
                :disabled="!resolutionText"
                large
                @click="cloreIncident"
                >Clore</v-btn
              >
            </v-col>
            <v-col cols="6" class="pa-0 mt-n3">
              <p class="text-justify">
                <strong>Note: </strong>L’intervention passera en statut résolu
              </p>
            </v-col>
          </v-row>
          <v-overlay absolute :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeResolutionDialog">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DatetimePicker from "../DatetimePicker";
import { PORTAL_CONFIG } from "../../config";
import TICKET_API from "../../api/ticket";
import USER_API from "../../api/user";
import { EventBus } from "../../event-bus";
import moment from "moment";
import { getColor, TASK_TYPE } from "../../utils";
import { mapGetters } from "vuex";

const allowProfiles = [32, 33, 34, 35, 36];
export default {
  props: {
    ticketId: {
      type: Number,
      required: true
    },
    group: {
      required: true
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number || null,
      required: true
    },
    ticketStatus: {
      required: true
    }
  },
  components: { DatetimePicker },
  data() {
    return {
      resolutionDialog: false,
      dateErrorMsg: "Date Interval invalid",
      task: {
        start: undefined,
        end: undefined,
        minutes: "60",
        content: undefined
      },
      resolutionText: undefined,
      alternative_email: undefined,
      emailLoading: false,
      resolutionLoading: false,
      taskLoading: false,
      dureeRules: [
        v => !!v || "en minutes",
        v => /^\d+$/.test(v) || "en minutes"
      ],
      rules: {
        required: value => !!value || "Required.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      overlay: false,
      errors: {
        affectPerson: undefined,
        resolution: undefined,
        task: undefined
      },
      error: undefined,
      success: false,
      ticketUser: null,
      selectedUser: null
    };
  },
  computed: {
    ...mapGetters(["getUsers", "getActiveEntity", "isPlanifClient"]),
    dateError() {
      if (this.task.start && this.task.end) {
        return moment(this.task.start).isAfter(moment(this.task.end));
      }
      return false;
    },
    canAddTask() {
      return !!this.task.start && !!this.task.content && !this.dateError;
    },
    usersList() {
      return this.getUsers.filter(
        el =>
          allowProfiles.includes(el.profiles_id) &&
          !PORTAL_CONFIG.HIDDEN_USERID.includes(parseInt(el.id)) &&
          !el.name.includes("_00") &&
          el.is_active == 1
      );
    },
    isPlanif() {
      return this.isPlanifClient;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    getUserName(user) {
      return user.firstname + " " + user.realname;
    },
    closeResolutionDialog() {
      this.resolutionDialog = false;
      this.alternative_email = undefined;
      this.resolutionText = undefined;
      this.errors.resolution = undefined;
      this.errors.affectPerson = undefined;
      this.errors.task = undefined;
    },
    affectPerson() {
      this.emailLoading = true;
      this.overlay = true;
      TICKET_API.affectPerson(this.ticketId, this.selectedUser)
        .then(response => {
          EventBus.$emit("ticket-resolu", this.ticketId);
          this.ticketUser = {
            id: response.data.id,
            users_id: this.selectedUser
          };
        })
        .catch(({ response }) => {
          response.data[1] =
            "ERREUR - Cette tâche est déjà attribuée à cet utilisateur";
          this.errors.affectPerson = response.data;
          this.setError(response.data[1]);
          this.overlay = false;
          this.emailLoading = false;
        })
        .finally(() => {
          this.setSuccessState();
          this.commentLoading = false;
          this.overlay = false;
          this.emailLoading = false;
          setTimeout(() => {
            this.closeResolutionDialog();
            EventBus.$emit("closeSuiviDialog");
          }, 2000);
        });
    },
    getITILFollowupByID(ITILFollowupID, closeModal = true) {
      TICKET_API.getITILFollowupByID(ITILFollowupID)
        .then(({ data }) => {
          let comment = data;
          comment.user_name = "";

          USER_API.getUserData(comment.users_id).then(({ data }) => {
            comment.user_name = data.firstname.concat(" ", data.realname);
            comment.color = getColor(comment.status);

            this.$emit("new-comment", comment);
            if (closeModal) {
              this.overlay = false;
              this.closeResolutionDialog();
            }
          });
        })
        .catch(() => {
          if (closeModal) {
            this.overlay = false;
          }
        });
    },
    cloreIncident() {
      this.resolutionLoading = true;
      this.overlay = true;
      TICKET_API.cloreIncident(
        this.ticketId,
        this.resolutionText,
        this.getActiveEntity.id
      )
        .then(({ data }) => {
          this.getITILSolutionByID(data.id);
          this.setSuccessState();
          EventBus.$emit("ticket-resolu", this.ticketId);
        })
        .catch(({ response }) => {
          this.errors.resolution = response.data;
          this.setError(response.data[1]);
        })
        .finally(() => {
          this.resolutionLoading = false;
          this.overlay = false;
          EventBus.$emit("closeSuiviDialog");
        });
    },
    addUser() {
      this.overlay = true;
      TICKET_API.findAttrib(this.ticketId, "2")
        .then(({ data }) => {
          let total = data.length;
          if (total > 0) {
            for (let i = 0; i < total; i++) {
              TICKET_API.delAttrib(this.ticketId, data[i].id);
            }
          }
        })
        .finally(() => {
          this.affectPerson(this.ticketId, this.selectedUser);
          this.overlay = false;
        });
    },
    addTask() {
      this.taskLoading = true;
      this.overlay = true;
      if (!this.task.minutes) {
        this.task.end = moment(this.task.start)
          .add(30, "m")
          .format("YYYY-MM-DD HH:mm");
      } else {
        this.task.end = moment(this.task.start)
          .add(this.task.minutes, "m")
          .format("YYYY-MM-DD HH:mm");
      }
      // Affecter le ticket a soit meme
      if (this.ticketStatus == 1 && !this.ticketUser) {
        TICKET_API.affectPerson(this.ticketId, this.selectedUser)
          .then(async () => {
            // Ajout de la planification
            TICKET_API.addTask(
              this.ticketId,
              this.task,
              this.group.id,
              this.selectedUser,
              this.getActiveEntity.id
            )
              .then(({ data }) => {
                this.getTaskByID(data.id);
                EventBus.$emit("ticket-resolu", this.ticketId);
              })
              .catch(({ response }) => {
                response.data[1] =
                  "ERREUR - Cette tâche est déjà attribuée à cette adresse mail";
                this.errors.task = response.data;
                this.setError(response.data[1]);
                this.overlay = false;
                this.taskLoading = false;
              });
          })
          .catch(({ response }) => {
            this.errors.task = response.data;
            this.setError(response.data[1]);
            this.overlay = false;
            this.taskLoading = false;
          });
      } else {
        TICKET_API.addTask(
          this.ticketId,
          this.task,
          this.group.id,
          this.selectedUser,
          this.getActiveEntity.id
        )
          .then(({ data }) => {
            this.getTaskByID(data.id);
            EventBus.$emit("ticket-resolu", this.ticketId);
          })
          .catch(({ response }) => {
            this.errors.task = response.data;
            this.setError(response.data[1]);
            this.overlay = false;
            this.taskLoading = false;
          });
      }
    },
    getTaskByID(id) {
      TICKET_API.getTaskByID(id)
        .then(({ data }) => {
          const task = data;
          task.actionType = TASK_TYPE;
          task.color = "warning";
          USER_API.getUserData(task.users_id).then(({ data }) => {
            task.user_name = data.firstname.concat(" ", data.realname);
            this.$emit("new-task", task);
            this.taskLoading = false;
            this.overlay = false;
            this.setSuccessState();
            this.initTask();
            this.closeResolutionDialog();
          });
        })
        .catch(({ response }) => {
          this.errors.task = response.data;
          this.setError(response.data[1]);
          this.overlay = false;
          this.taskLoading = false;
        });
    },
    initTask() {
      this.task.start = undefined;
      this.task.end = undefined;
      this.task.content = undefined;
    },

    setError(errorMsg) {
      this.error = {
        message: errorMsg
      };
      setTimeout(() => {
        this.error = undefined;
      }, 5000);
    },
    setSuccessState() {
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 5000);
    },
    getITILSolutionByID(ITILSolutionID) {
      TICKET_API.getITILSolutionByID(ITILSolutionID)
        .then(({ data }) => {
          data.actionType = 1;
          data.color = getColor(data.status);
          if (!data.user_name) data.user_name = "Moi";

          if (!data.content) {
            if (data.status === 4)
              data.content = `Refusé le ${new Date(
                data.date_approval
              )} par KeepInTouch`;
          }

          this.$emit("new-solution", data);
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
        })
        .finally(() => {
          this.closeResolutionDialog();
        });
    },
    async loadTicketUser() {
      this.emailLoading = true;
      await TICKET_API.getTicketUser(this.ticketId)
        .then(({ data }) => {
          this.ticketUser = data.find(el => el.type === 2);
          this.selectedUser = this.ticketUser ? this.ticketUser.users_id : null;
        })
        .finally(() => {
          this.emailLoading = false;
        });
    },
    deleteTicketUser() {
      this.overlay = true;
      TICKET_API.deleteTicketUser(this.ticketId, this.ticketUser.id)
        .then(() => {
          this.overlay = false;
          this.ticketUser = null;
          this.selectedUser = null;
        })
        .catch(() => {
          this.overlay = false;
        });
    }
  },
  created() {
    this.loadTicketUser();
  }
};
</script>
