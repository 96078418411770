import { reactive, toRefs } from "@vue/composition-api";
//import USER_API from "../api/user";
import { getColor, loadStates } from "../utils";
import loadDocuments from "./fetchDocumentToFollowUp";
export default function getFollowUps(ticketId, followUps) {
  const state = reactive({
    ITILFollowupLoaded: loadStates.INITIAL_STATE,
    ITILFollowup: []
  });
  const loadITFollowUps = async () => {
    state.ITILFollowupLoaded = loadStates.PENDING;
    state.ITILFollowup = followUps.value.map(el => {
      el.user_name = "...";
      return el;
    });
    // Load user name for each followups
    // CCA modif l'affichage du nom des rédacteurs de commentaires mais pas des photos
    state.ITILFollowup.forEach(async el => {
      /*await USER_API.getUserData(el.users_id).then(({ data }) => {
        state.ITILFollowup[
          index
        ].user_name = `${data.firstname} ${data.realname}`;
      });*/
      el.color = getColor(el.status);
    });
    state.ITILFollowupLoaded = loadStates.SUCCESS;
    const docs = await loadDocuments(ticketId);
    Promise.all(docs).then(results => {
      results.forEach(doc => {
        state.ITILFollowup.push(doc);
      });
    });
  };

  return {
    ...toRefs(state),
    loadITFollowUps
  };
}
